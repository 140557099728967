module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Life4Water","short_name":"Life4Water","description":"Stavíme budoucnost s vodou. Od projektu po realizaci. Vodohospodářské a ekologické stavby. Nakládání s dešťovou vodou. Dotační poradenství.","lang":"cs","display":"standalone","start_url":"/","background_color":"#ffffff","theme_color":"#5bbad5","icons":[{"src":"src/assets/meta/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"src/assets/meta/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/meta/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/assets/meta/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/meta/favicon-16x16.png","sizes":"16x16","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/sluzby/*"]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
